﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.blog-social-media-share {
    position: fixed;
    margin: 0;
    padding: 0;
    z-index: 950;
    right: 0;
    bottom: 0;
    width: 100vw;

    @include for-tablet-landscape-up {
        top: 200px;
        bottom: auto;
        width: auto;
    }

    &__item {
        list-style: none;
        display: inline-block;

        @include for-tablet-landscape-up {
            display: block;
        }
    }

    &__button {
        text-decoration: none;
        color: #000;
        background-color: $grey-230;
        width: 25vw;
        height: 5.5rem;
        font-size: 2.8rem;
        line-height: 5.5rem;
        text-align: center;
        display: block;

        @include for-tablet-landscape-up {
            width: 5.5rem;
            transition: all 0.2s ease-in;

            &:hover {
                width: 7.5rem;
                padding-left: 1rem;
            }
        }
    }
}

//hide sharing icons in exp editor
.in-experience-editor .blog-social-media-share {
    display: none;
}